
import { Vue, Component, Watch, Provide } from 'vue-property-decorator'
import BookingHeader from '@/components/BookingHeader.vue'
import NotFoundLayout from '@/layouts/NotFoundLayout.vue'
import QuoteConfirmation from '@/components/QuoteConfirmation.vue'
import QuoteCard from '@/components/QuoteCard.vue'
import QuoteDetailBidList from '@/components/QuoteDetailBidList.vue'
import QuoteNotOwned from '@/components/QuoteNotOwned.vue'
import SkeletonBidCard from '@/components/SkeletonBidCard.vue'
import SkeletonTripCollectionCard from '@/components/SkeletonTripCollectionCard.vue'
import ShareQuoteDialog from '@/components/ShareQuoteDialog.vue'
import DownloadQuoteButton from '@/components/DownloadQuoteButton.vue'
import TwoColumnLayout from '@/layouts/TwoColumnLayout.vue'
import CharterUPValueProps from '@/components/CharterUPValueProps.vue'
import SkeletonBox from '@/components/SkeletonBox.vue'
import support from '@/store/modules/support'
import quotes from '@/services/quotes'
import modules from '@/store/modules'
import auth from '@/store/modules/auth'
import { AxiosResponse } from 'axios'
import { GenericApiResult } from '@/models/dto'
import { SplitFeatureFlag } from '@/utils/enum'
import { isQuoteDetailActive } from '@/utils/quote'
import { datetimeToShortLocalizedDateTime } from '@/utils/datetime'
import { QuoteDetailV2, QuoteDetailV2Bid } from '../models/dto/QuoteDetailV2'
import { PartnerTypeId, PricingMethod, ValuePropKey, Page } from '../utils/enum'

@Component({
  name: 'QuoteDetail',
  components: {
    NotFoundLayout,
    TwoColumnLayout,
    QuoteDetailBidList,
    SkeletonBidCard,
    SkeletonTripCollectionCard,
    BookingHeader,
    QuoteConfirmation,
    QuoteNotOwned,
    ShareQuoteDialog,
    DownloadQuoteButton,
    QuoteCard,
    CharterUPValueProps,
    SkeletonBox,
  },
})
export default class QuoteDetail extends Vue {
  @Provide('isInQuoteDetail') isInQuoteDetail = true
  loaded = false
  quote: QuoteDetailV2 = null
  featuredBid: QuoteDetailV2Bid = null
  notFound = false
  userDoesNotOwnQuote = false
  mode = ''
  refreshInterval: any = null
  support = support
  ValuePropKey = ValuePropKey
  page = Page.QuoteDetail
  valuePropKeys: ValuePropKey[] = [
    ValuePropKey.BookingProtection,
    ValuePropKey.Tracking,
    ValuePropKey.LiveSupport,
    ValuePropKey.QualifiedOperators,
  ]

  isBrandedQuoteFlowEnabled = false

  get featuredCompanyId(): number | undefined {
    return this.featuredBid?.companyId
  }

  get isReseller(): boolean {
    return !!this.featuredBid?.reseller
  }

  get showDownloadQuoteButton(): boolean {
    return this.isBrandedQuoteFlowEnabled && this.quote?.hash && this.isReseller
  }

  get bidsExpirationDate(): string {
    return datetimeToShortLocalizedDateTime(this.quote?.expirationDate)
  }

  get featuredBidCompanyDBAName(): string {
    return this.featuredBid?.companyDBAName
  }

  get bidsExist(): boolean {
    return (
      (this.quote?.bids && this.quote?.bids.length !== 0) || !!this.featuredBid
    )
  }

  get bronzeBids(): QuoteDetailV2Bid[] {
    return this.quote?.bids?.filter((bid) => {
      return bid.partnerTypeId === PartnerTypeId.Bronze
    })
  }

  // Show bid expiration stamp if there bids that are not all bronze bids and we have a timestamp
  get showBidsExpiration(): boolean {
    return (
      this.bidsExist &&
      this.quote?.bids.length !== this.bronzeBids?.length &&
      !!this.bidsExpirationDate
    )
  }

  get showShareQuoteDialog(): boolean {
    if (!this.loaded || !this.quote) {
      return false
    }
    return this.isUserQuoteCustomer || this.isUserInSameOrganization
  }

  get isUserQuoteCustomer(): boolean {
    const quoteCustomerId = this.quote.trips?.[0]?.customerId
    return quoteCustomerId === auth.user?.userId
  }

  get isUserInSameOrganization(): boolean {
    const quoteCustomerCustomerAccountId = this.quote?.customer
      ?.customerAccountId
    if (!quoteCustomerCustomerAccountId) {
      return false
    }
    return (
      quoteCustomerCustomerAccountId ===
        auth.customerAccount.customerAccountId ||
      auth.childCustomerAccountIds.includes(quoteCustomerCustomerAccountId)
    )
  }

  get isMobile() {
    return this.$vuetify.breakpoint.smAndDown
  }

  async mounted(): Promise<void> {
    this.mode = this.$route.params.mode === 'confirm' ? 'confirm' : 'bids'
    await this.getQuote()

    this.isBrandedQuoteFlowEnabled = await this.$split.isFeatureEnabled(
      SplitFeatureFlag.BrandedQuoteFlow
    )

    this.$nextTick(() => {
      this.loaded = true
      modules.quotes.fetchUnreadQuoteCount()
    })
    this.processFeatured()
  }

  beforeDestroy(): void {
    clearInterval(this.refreshInterval)
  }

  validateQuoteDetail(quoteDetail: QuoteDetailV2): boolean {
    // If the quote is a bids quote, return true so we see bids
    if (quoteDetail?.pricingMethod === PricingMethod.Bids) {
      return true
    }
    // If the quote is an unconfirmed category, non-Salesbot quote, return true so we see confirmation page
    if (
      !quoteDetail.isSelfServe &&
      quoteDetail?.pricingMethod === PricingMethod.Category &&
      !quoteDetail.isConfirmed
    ) {
      return true
    }

    if (quoteDetail.isElite) {
      return true
    }

    if (!quoteDetail?.bids?.length) {
      this.$router.push({ name: 'quote-index' })
      return
    }

    this.$router.push({
      name: 'checkout-single-bid',
      params: { quoteId: quoteDetail.quoteId.toString() },
    })
  }

  processFeatured(): void {
    const bids = this.quote?.bids
    if (!bids?.length) {
      return
    }

    const featuredArray = bids?.filter(({ featured }) => featured)
    if (featuredArray.length === 1) {
      this.featuredBid = featuredArray[0]
    }
    this.quote.bids = bids.filter(({ featured }) => !featured)
  }

  async getQuote(): Promise<void> {
    const { id, hash } = this.$route.params
    let response: AxiosResponse<GenericApiResult<QuoteDetailV2>>

    const isByHash = !!hash
    const isById = !!id

    try {
      if (isById) {
        response = await quotes.detailV2(parseInt(id))
      } else if (isByHash) {
        response = await quotes.byHashV2(hash)
      }
      if (!response) {
        this.userDoesNotOwnQuote = true
        return
      }
    } catch (e) {
      this.notFound = true
      return
    }

    const quoteDetail: QuoteDetailV2 = response.data.data

    if (!isQuoteDetailActive(quoteDetail)) {
      this.$router.push({ name: 'quote-index' })
      return
    }

    this.mode = quoteDetail.isConfirmed ? 'bids' : 'confirm'

    if (!this.validateQuoteDetail(quoteDetail)) {
      return
    }

    this.quote = quoteDetail

    // Update URL if the quote was fetched by hash
    if (hash && id !== String(this.quote.quoteId)) {
      this.$router.replace(`/quotes/${this.quote.quoteId}`)
    }

    this.refreshInterval = setInterval(() => this.refreshQuote(), 10000)
  }

  async refreshQuote(): Promise<void> {
    try {
      const response = await quotes.detailV2(this.quote.quoteId)
      const quoteDetail = response.data.data
      this.mode = quoteDetail.isConfirmed ? 'bids' : 'confirm'
      if (!this.validateQuoteDetail(quoteDetail)) {
        return
      }
      this.quote = quoteDetail
      this.processFeatured()
    } catch (e) {
      console.warn(e)
      this.$router.push({ name: 'quote-index' })
      return
    }
  }
  async confirmQuote() {
    if (this.quote?.pricingMethod === PricingMethod.Category) {
      await this.$router.push({
        name: 'checkout-single-bid',
        params: { quoteId: this.quote.quoteId.toString() },
      })
    }
    this.mode = 'bids'
  }
}
